/**
 * Конфигурационный объект, содержащий URL-адреса для различных методов API и список запрещенных адресов электронной почты.
 *
 * @property {string} fetchBooking - URL-адрес API для получения информации о бронировании.
 * @property {string} fetchAccounts - URL-адрес API для получения списка учетных записей.
 * @property {string} addPayment - URL-адрес API для добавления информации об оплате.
 * @property {string} saveFiles - URL-адрес API для сохранения файлов.
 * @property {Array<string>} forbiddenEmails - Список адресов электронной почты, которые запрещены для использования в авторизации.
 */

const oldHash = 'AKfycbxyC-BN2cNkUx_wRvhDNrDj6htKSvNoZBJlUfi-8pzBYuwef4xSusVileMWDX1ATFVr'
const newHash = 'AKfycbxyC-BN2cNkUx_wRvhDNrDj6htKSvNoZBJlUfi-8pzBYuwef4xSusVileMWDX1ATFVr'

const config = {
    fetchBooking: 'https://functions.yandexcloud.net/d4ek64oauvunultmcaeq?method=fetchBooking',
    fetchAccounts: `https://script.google.com/macros/s/${newHash}/exec?methodName=fetchAccounts`,
    addPayment: `https://script.google.com/macros/s/${newHash}/exec?methodName=addPayment`,
    saveAdditionalService: `https://script.google.com/macros/s/${newHash}/exec?methodName=saveAdditionalService`,
    saveFiles: `https://script.google.com/macros/s/${newHash}/exec?methodName=saveFiles`,
    fetchUserOperations: 'https://functions.yandexcloud.net/d4ek64oauvunultmcaeq?method=fetchUserOperations',
    deleteUserOperation: 'https://functions.yandexcloud.net/d4ek64oauvunultmcaeq?method=deleteUserOperation',
    // Используется как прокси (то есть приходит запрос и переадресирует на другой скрипт, так как без этого возникает CORS ошибка)
    // addRefundPayment: 'https://script.google.com/macros/s/AKfycbz41GeKIBj0YS6ri7_WwlOY9NHMRNvhKZ0eSY26Nr2tDh5-WFgr75mYG7JGPu1gjYqF/exec?methodName=addRefundPayment',
    addRefundPayment: `https://script.google.com/macros/s/${newHash}/exec?methodName=addRefundPayment`,
    createInProgressRefundOperation:
      'https://script.google.com/macros/s/AKfycbwX369m6mTyDvwLY1ykQoLjgX3OT_HZfKgmga3CtD8D1949312MWsiWJ1x1MXJOo0BaAA/exec',
    // axis-finance script (передается в скрипт который выше в качестве параметра, дабы шустрее делать обновления)
    // тестовый скрипт
    // addRefundAxisFinanceScriptId: '1bkzdS5cFqcS2CZLPvjw8-CfVP9JPSmSgotC-3bVFFi1OnjIcgHTinJLj',
    addRefundAxisFinanceScriptId: 'AKfycbx8-2f_8ee1cnzJwG81elzle8dRf_w1iTpKAaKvzrchRBLCovqcitKQ9HTRiPUneM8CHw',
    yandexScriptUrl: 'https://functions.yandexcloud.net/d4ek64oauvunultmcaeq',
    forbiddenEmails: ['axisaparts@gmail.com'],
    // Отели которые отображаются в интерфейсе
    hotels: {
        AxisIsland: {
            id: 6054,
            text: 'Axis.Moscow Island',
        },
        AxisArbat: {
            id: 21696,
            text: 'Axis.Moscow Arbat',
        },
        AxisColors: {
            id: 19116,
            text: 'Axis.Moscow Colors',
        },
        Jackpot: {
            id: 11980,
            text: 'Jackpot Loft Apartments',
        },
        Raido: {
            id: 29937,
            text: 'Raido.Moscow Forest',
        },
        RaidoBigCity: {
            id: 32323,
            text: 'Raido.Moscow Big City',
        },
        myasnitsky: {
            id: 39088,
            text: 'PR Hotel Myasnitsky',
        },
        // добавлен новый отель
        AxisHeritage: {
            id: 40895,
            text: 'Axis.Moscow Heritage',
        },
    }
}
